/* Tracking.css */
.tracking {
  padding: 4rem 2rem;
  text-align: center;
}

.tracking h1 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  animation: slideInDown 0.8s ease;
}

.tracking form {
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  animation: fadeIn 1s ease;
}

.tracking label {
  text-align: left;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.tracking input {
  width: 100%;
  padding: 0.8rem;
  margin-bottom: 1.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.tracking button {
  background-color: #ffcc00;
  color: black;
  border: none;
  padding: 1rem 2rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tracking button:hover {
  background-color: #ff9900;
}

@keyframes slideInDown {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
