/* Home.css */
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  background-image: url("https://source.unsplash.com/featured/?ship,ocean");
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  padding: 0 2rem;
  height: auto;
}

.hero h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  animation: fadeInDown 1s ease;
}

.hero p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  animation: fadeInUp 1.2s ease;
}

.cta-btn {
  background-color: #ffcc00;
  color: black;
  border: none;
  padding: 1rem 2rem;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.cta-btn:hover {
  background-color: #ff9900;
  transform: translateY(-2px);
  transition: all 0.3s ease;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
