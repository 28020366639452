/* BottomBar.css */
.bottom-bar {
  background-color: #333;
  color: white;
  padding: 3rem;
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  flex-wrap: wrap;
}

.about,
.social-media,
.payment {
  margin-bottom: 2rem;
}

.heading {
  font-size: 1.5rem;
  color: #ffcc00;
  margin-bottom: 1rem;
}

/* Social Media Icons */
.social-media ul {
  display: flex;
  gap: 1rem;
}

.social-media ul li {
  list-style: none;
}

.social-media ul li a {
  color: white;
  font-size: 2rem;
}

.social-media ul li a:hover {
  color: #ffcc00;
}

/* Payment Icons */
.payment ul {
  display: flex;
  gap: 1rem;
}

.payment ul li {
  list-style: none;
  font-size: 2rem;
}

.payment ul li svg {
  color: white;
}

.payment ul li svg:hover {
  color: #ffcc00;
}

/* Links Section */
.links ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.links ul li {
  margin-bottom: 0.5rem;
}

.links ul li a {
  color: white;
  text-decoration: none;
}

.links ul li a:hover {
  color: #ffcc00;
}

/* Recent Posts */
.posts ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.posts ul li {
  display: flex;
  margin-bottom: 1rem;
}

.posts .image img {
  width: 80px;
  height: 80px;
  margin-right: 1rem;
}

.posts .details {
  display: flex;
  flex-direction: column;
}

.posts .title {
  color: white;
  margin: 0;
}

.posts .date {
  color: #bbb;
  font-size: 0.9rem;
}

/* App Download Icons */
.download ul {
  display: flex;
  gap: 1rem;
}

.download ul li a {
  font-size: 2rem;
  color: white;
}

.download ul li a:hover {
  color: #ffcc00;
}

/* Responsive */
@media (max-width: 768px) {
  .row {
    flex-direction: column;
  }

  .posts ul li {
    flex-direction: column;
  }

  .posts .image img {
    width: 100%;
  }
}
.aboutus {
  width: 30%;
}
