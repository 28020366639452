/* Contact.css */
.contact-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 2rem;
}

/* First column: Contact Info and Form */
.contact-column {
  flex: 1;
  min-width: 300px;
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.info-row {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background-color: #f0f0f0;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Added for scaling */
}

.info-row:hover {
  transform: scale(1.05); /* Scale on hover */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover */
}

.info-item h3 {
  margin-bottom: 0.5rem;
  color: #499ad3;
}

.info-item p {
  margin: 0;
}

.contact-form {
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Added for scaling */
}

.contact-form:hover {
  transform: scale(1.05); /* Scale on hover */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover */
}

.contact-form h3 {
  margin-bottom: 1rem;
  color: #499ad3;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  font-size: 1rem;
}

.contact-form button {
  padding: 0.75rem 1.5rem;
  border: none;
  background-color: #499ad3;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 4px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.contact-form button:hover {
  background-color: #ffcc00;
  transform: scale(1.05); /* Slight scale effect on button hover */
}

/* Second column: Map */
.map-column {
  flex: 1;
  min-width: 300px;
  padding: 1.5rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Added for scaling */
}

.map-column:hover {
  transform: scale(1.05); /* Scale on hover */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover */
}

.map-container iframe {
  width: 100%;
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Responsive Layout */
@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
  }

  .contact-column,
  .map-column {
    width: 100%;
  }
}
