/* TopBar.css */
.top-bar {
  background-color: white;
  min-height: 100px;
  padding: 15px 0px;
  color: #272662;
  font-weight: bolder;
  position: relative;
  transition: top 0.3s ease;
}

.top-bar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: flex;
  justify-content: space-evenly;
}

.top-bar-logo {
  font-size: 1.2rem;
  font-weight: bold;
}

.top-bar-contact {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.top-bar-socials a {
  color: black;
  text-decoration: none;
  margin-left: 1rem;
}

.top-bar-socials a:hover {
  color: #ffcc00;
}

/* Hide TopBar on Scroll */
.hide-top-bar {
  top: -50px;
}

.btn {
  background-color: #499ad3;
  border: none;
  margin: 5px;
}

.btn:hover {
  background-color: #272662;
}
