/* Navbar.css */
.navbar-container {
  position: absolute;
  width: 100%;
}

.navbar-top {
  top: 0; /* Move the navbar to the top */
}

/* Sticky Navbar */
.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #499ad3;
  color: white;
  position: sticky;
  top: 0;
  z-index: 1000;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  min-height: 70px;
}

.navbar-logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.navbar-links {
  display: flex;
  gap: 2rem;
  list-style: none;
}

/* Add border-right to each navbar link */
.navbar-links li {
  position: relative;
  padding: 0 1rem; /* Add padding for spacing */
  border-right: 1px solid white;
  transition: border-color 0.3s ease;
}

/* Remove border from last item */
.navbar-links li:last-child {
  border-right: none;
}

.navbar-links a {
  color: white;
  text-decoration: none;
  position: relative;
  font-size: 1rem;
  display: block;
  padding: 0.5rem 0;
  transition: color 0.3s ease, background-color 0.3s ease;
}

/* Hover Effect - Add a smooth background color transition */
.navbar-links li:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Light background on hover */
  border-color: #272662; /* Change border color on hover */
}

.navbar-links a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: #272662;
  transition: width 0.3s ease;
}

.navbar-links a:hover::after {
  width: 100%; /* Full underline on hover */
}

.menu-icon {
  display: none;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  height: 22px;
  width: 28px;
}

.menu-line {
  width: 100%;
  height: 3px;
  background-color: white;
  transition: all 0.3s ease;
}

/* Sidebar for Mobile */
.sidebar {
  position: fixed;
  top: 0;
  left: -250px;
  height: 100%;
  width: 250px;
  background-color: #0a74da;
  transition: left 0.3s ease;
  padding-top: 4rem;
  z-index: 100;
}

.sidebar-open {
  left: 0;
}

.sidebar-header {
  padding: 1rem 2rem;
  color: white;
}

.sidebar-socials a {
  color: white;
  text-decoration: none;
  margin-right: 1rem;
}

.sidebar-socials a:hover {
  color: #272662;
}

.sidebar ul {
  list-style: none;
  padding-left: 2rem;
}

.sidebar ul li {
  margin-bottom: 1.5rem;
}

.sidebar ul a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  display: block;
  transition: color 0.3s ease;
}

.sidebar ul a:hover {
  color: #272662;
}

@media (max-width: 768px) {
  .navbar-links {
    display: none;
  }

  .menu-icon {
    display: flex;
  }
}
