/* About.css */
.about {
  padding: 4rem 2rem;
  text-align: center;
}

.about h1 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  animation: fadeIn 0.8s ease;
}

.about p {
  font-size: 1.2rem;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.8;
  animation: fadeIn 1.2s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
