/* index.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Add RTL support in your CSS */
html[lang="ar"] {
  direction: rtl;
  text-align: right;
}

html[lang="en"] {
  direction: ltr;
  text-align: left;
}

body {
  font-family: "Arial", sans-serif;
  background-color: #f4f4f4;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  cursor: pointer;
}

input,
textarea {
  width: 100%;
  padding: 0.5rem;
  margin: 0.5rem 0;
}

button {
  padding: 0.5rem 1rem;
  background-color: #499ad3;
  border: none;
}

button:hover {
  background-color: #272662;
}

/* Responsive design */
@media (max-width: 768px) {
  .navbar-links {
    flex-direction: column;
  }

  .cta-btn {
    width: 100%;
  }
}
