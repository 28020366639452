/* Services.css */
.services-container {
  padding: 8rem;
  text-align: center;
}

.services-container h2 {
  font-size: 2.5rem;
  color: #499ad3;
  margin-bottom: 2rem;
}

/* Grid layout with a maximum of 4 items per row */
.services-grid {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(250px, 1fr)
  ); /* Responsive grid with minimum width for items */
  gap: 2rem;
  max-width: 100%; /* Ensures grid doesn't stretch beyond screen */
}

/* Service card styling */
.service-card {
  position: relative;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effect */
  cursor: pointer;
}

.service-image {
  height: 400px; /* Set a fixed height for the image container */
  overflow: hidden;
  border-radius: 10px 10px 0 0;
}

.service-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image fits the container while maintaining its aspect ratio */
  display: block;
}

/* Service title always visible */
.service-title {
  background-color: #499ad3;
  color: white;
  padding: 1rem;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.service-title h3 {
  margin: 0;
  font-size: 1.2rem;
  text-transform: uppercase;
}

/* Service content (description and button) initially hidden */
.service-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1.5rem;
  background-color: rgba(0, 0, 0, 0.85);
  color: white;
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 0.4s ease, transform 0.4s ease;
}

/* Reveal description and button on hover */
.service-card:hover .service-content {
  opacity: 1;
  transform: translateY(0); /* Slide up the content */
}

.service-content p {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.more-btn {
  background-color: #ffcc00;
  color: #333;
  border: none;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.more-btn:hover {
  background-color: #ffa500;
}

/* Mobile view: show content without hover */
@media (max-width: 768px) {
  .service-content {
    position: static;
    opacity: 1;
    transform: none; /* Always visible on mobile */
    background-color: transparent;
    color: #333;
    padding: 1rem 1.5rem;
  }

  .service-content p {
    color: #555;
  }

  .service-card {
    padding-bottom: 1.5rem;
  }

  .service-title {
    padding: 0.5rem;
  }

  .service-title h3 {
    font-size: 1rem;
  }
}

/* Set a maximum of 4 items per row */
@media (min-width: 1200px) {
  .services-grid {
    grid-template-columns: repeat(3, 1fr); /* Max 4 columns on large screens */
  }
}

@media (max-width: 720px) {
  .services-container {
    padding: 2rem;
  }
}
