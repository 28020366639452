/* FloatingWhatsAppButton.css */
.floating-whatsapp {
  position: fixed;
  bottom: 150px;
  right: 20px;
  background-color: #25d366; /* WhatsApp green color */
  color: white;
  font-size: 2.5rem;
  border-radius: 50%;
  padding: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  transition: transform 0.3s ease;
  width: 60px;
  height: 60px;
}

.floating-whatsapp:hover {
  transform: scale(1.1); /* Slight zoom effect on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .floating-whatsapp {
    font-size: 2rem;
    bottom: 15px;
    right: 15px;
  }
}
