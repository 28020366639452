.enquiry-container {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #f9f9f9;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

h2 {
  text-align: center;
  margin-bottom: 2rem;
}

h3 {
  margin-bottom: 1rem;
}

/* Tabs Styling */
.tabs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 1rem;
  border-bottom: 2px solid #ccc;
}

.tab.active {
  border-bottom: 2px solid #1479ea;
}

.tab-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.done-icon {
  font-size: 1rem;
  color: green;
  margin-top: 0.25rem;
}

/* Style the services list as cards */
.services-list {
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}

.services-list li {
  margin-bottom: 1rem;
}

.services-list label {
  display: block;
  padding: 1.5rem;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
}

.services-list label:hover {
  background-color: #f1f1f1;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

/* Active state styling for selected service */
.services-list input[type="radio"]:checked + label {
  background-color: #1479ea;
  color: white;
  border-color: #1479ea;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

.services-list input[type="radio"] {
  display: none; /* Hide the radio input */
}

.services-list label > span {
  font-size: 1.2rem;
  font-weight: bold;
}

input[type="text"],
input[type="date"] {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 0.75rem 1.5rem;
  border: none;
  background-color: #499ad3;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:disabled {
  background-color: #ccc;
}

button:hover:not(:disabled) {
  background-color: #1479ea;
}

.form-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}
textarea {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
}
